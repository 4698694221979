import { getDefaultUser } from './profile';
import logger from './logger';
import getElementById from './guarded-getElementById';
import { onLoad } from './onload';

function hide(elemId: string): void {
    getElementById(elemId)
        .then(elem => elem.classList.add("hidden"))
}

function show(elemId: string): void {
    getElementById(elemId)
        .then(elem => elem.classList.remove("hidden"))

}

onLoad(async () => {
    const user = await getDefaultUser();
    logger().debug("in profile load");
    logger().debug(user);

    if (user.uid) {
        const headerProfileName = await getElementById('header-profile-name');
        headerProfileName.innerHTML = user.preferredName;

        let img = new Image();
        img.src = user.photoURL;
        (await getElementById('header-profile-pic')).replaceChildren(img);
        hide('header-login')
        hide('pricing-link')
        hide('technology-link')

        show('header-profile')
        show('generate-link')
        show('recipe-link')
    }
})